import { get } from 'lodash';

import ContxtSDK from '@ndustrial/contxt-sdk';

import AdminService from './AdminService';
import EmptyService from './EmptyService';
import { contxtSdk, setGlobalContxtSdk } from './global';

export type ContxtSdkServicesEnum =
  | 'contxtAuth'
  | 'coordinator'
  | 'events'
  | 'facilities'
  | 'files'
  | 'iot'
  | 'nionic';

export type ContxtSdkServicesExternalModulesEnum =
  | 'admin'
  | 'coincident_peak'
  | 'ems'
  | 'iot_v2'
  | 'nionic'
  | 'rates';

export type ContxtSdkKeys =
  | ContxtSdkServicesEnum
  | ContxtSdkServicesExternalModulesEnum;

export interface SdkConfigItem {
  env?: string;
  clientId?: string;
  host?: string;
}

export type SdkConfigCustomModules = Partial<
  Record<ContxtSdkServicesEnum, SdkConfigItem>
>;

export interface SdkConfig {
  domain: string;
  customModuleConfigs: SdkConfigCustomModules;
}

export interface ContxtSdkExternalServicesType {
  clientId: string;
  host: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  module: any;
}

export type ContxtSdkExternalModulesType = Partial<
  Record<ContxtSdkServicesExternalModulesEnum, ContxtSdkExternalServicesType>
>;

export type ClientIdEnum = 'staging' | 'production';

export interface ContxtModuleItem {
  clientIdEnv: ClientIdEnum;
  host: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  module: any;
}

function convertExternalConfig(
  key: ContxtSdkServicesExternalModulesEnum,
  config: ContxtModuleItem
) {
  return {
    clientId:
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      config!.clientIdEnv === 'production'
        ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          prodExternalModules[key]!.clientId
        : // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          stagingExternalModules[key]!.clientId,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    host: config!.host,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    module: config!.module
  };
}

function convertSdkConfig(
  key: ContxtSdkServicesEnum,
  config: ContxtModuleItem
) {
  return {
    clientId:
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      config!.clientIdEnv === 'production'
        ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          prodSdkConfig.customModuleConfigs[key]!.clientId
        : // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          stagingSdkConfig.customModuleConfigs[key]!.clientId,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    host: config!.host,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    module: config!.module
  };
}

/**
 *
 * @param env if this is not set to 'production' it will default to 'staging'
 * @param clientId this is the current applications client id
 * @param stagingOverrides list of service names to force to use the staging configuration
 * @param productionOverrides list of service names to force to use the production configuration - by default contxtAuth, coordinator, admin are using production (even if env is set as staging)
 * @param moduleOverrides object which can be used to override service objects. this can be used to test different hosts, like localhost
 * @returns the contxtSdk
 */
export function getContxtSdk(
  env: string,
  clientId: string,
  stagingOverrides?: ContxtSdkKeys[],
  productionOverrides?: ContxtSdkKeys[],
  moduleOverrides?: Partial<Record<ContxtSdkKeys, ContxtModuleItem>>,
  useStagingAuth = false
) {
  if (!contxtSdk) {
    let config: SdkConfig;
    let externalModules: ContxtSdkExternalModulesType;

    if (env === 'production') {
      config = prodSdkConfig;
      externalModules = prodExternalModules;
    }
    // for anything other than prod, use staging
    else {
      config = stagingSdkConfig;
      externalModules = stagingExternalModules;
    }
    if (stagingOverrides) {
      stagingOverrides.forEach((item) => {
        if (Object.keys(config.customModuleConfigs).includes(item)) {
          config.customModuleConfigs[item as ContxtSdkServicesEnum] =
            stagingSdkConfig.customModuleConfigs[item as ContxtSdkServicesEnum];
        } else if (Object.keys(externalModules).includes(item)) {
          externalModules[item as ContxtSdkServicesExternalModulesEnum] =
            stagingExternalModules[
              item as ContxtSdkServicesExternalModulesEnum
            ];
        }
      });
    }
    if (productionOverrides) {
      productionOverrides.forEach((item) => {
        if (Object.keys(config.customModuleConfigs).includes(item)) {
          config.customModuleConfigs[item as ContxtSdkServicesEnum] =
            prodSdkConfig.customModuleConfigs[item as ContxtSdkServicesEnum];
        } else if (Object.keys(externalModules).includes(item)) {
          externalModules[item as ContxtSdkServicesExternalModulesEnum] =
            prodExternalModules[item as ContxtSdkServicesExternalModulesEnum];
        }
      });
    }
    if (moduleOverrides) {
      for (const key in moduleOverrides) {
        // normal service module
        if (Object.keys(config.customModuleConfigs).includes(key)) {
          config.customModuleConfigs[key as ContxtSdkServicesEnum] = {
            ...config.customModuleConfigs[key as ContxtSdkServicesEnum],
            ...moduleOverrides[key as ContxtSdkKeys]
          };
          config.customModuleConfigs[key as ContxtSdkServicesEnum] =
            convertSdkConfig(
              key as ContxtSdkServicesEnum,
              moduleOverrides[key as ContxtSdkKeys]!
            );
        }
        // external module
        else if (Object.keys(externalModules).includes(key)) {
          externalModules[key as ContxtSdkServicesExternalModulesEnum] =
            convertExternalConfig(
              key as ContxtSdkServicesExternalModulesEnum,
              moduleOverrides[key as ContxtSdkKeys]!
            );
        }
      }
    }

    // With this flag we'll swap out the various services required to use the staging auth0 flow.
    if (useStagingAuth) {
      config.domain = 'contxt-staging.us.auth0.com';
      if (config.customModuleConfigs.contxtAuth?.env)
        config.customModuleConfigs.contxtAuth.env = 'staging';
      if (config.customModuleConfigs.coordinator?.env)
        config.customModuleConfigs.coordinator.env = 'staging';
      if (externalModules.admin) {
        externalModules.admin.clientId = 'qGzdTXcmB57zlTp86rYsivG9qEss1lbF';
        externalModules.admin.host = 'https://contxt.api.staging.ndustrial.io';
      }
    }

    return setGlobalContxtSdk(
      new ContxtSDK({
        config: {
          auth: {
            clientId,
            env: env === 'production' ? 'production' : 'staging',
            ...config
          },
          interceptors: {
            response: [
              {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                rejected: (err: any) => {
                  if (get(err, 'response.status') === 401) {
                    contxtSdk.auth.logOut();
                  }

                  return Promise.reject(err);
                }
              }
            ]
          }
        },
        externalModules,
        sessionType: 'auth0WebAuth'
      })
    );
  } else {
    return contxtSdk;
  }
}

const prodSdkConfig: SdkConfig = {
  domain: 'ndustrial.auth0.com',
  customModuleConfigs: {
    contxtAuth: {
      env: 'production',
      clientId: '75wT048QcpE7ujwBJPPjr263eTHl4gEX' // this is needed for custom overrides
    },
    coordinator: {
      env: 'production',
      clientId: '8qY2xJob1JAxhmVhIDLCNnGriTM9bct8' // this is needed for custom overrides
    },
    events: {
      env: 'production',
      clientId: '7jzwfE20O2XZ4aq3cO1wmk63G9GzNc8j' // this is needed for custom overrides
    },
    facilities: {
      env: 'production',
      clientId: 'SgbCopArnGMa9PsRlCVUCVRwxocntlg0' // this is needed for custom overrides
    },
    files: {
      env: 'production',
      clientId: 'VZJ2MRd71dM9oX2hB1EVAEjUe6loL7pl' // this is needed for custom overrides
    },
    iot: {
      env: 'production',
      clientId: 'iznTb30Sfp2Jpaf398I5DN6MyPuDCftA' // this is needed for custom overrides
    },
    nionic: {
      env: 'production',
      clientId: 'vtiZlMRo4apDvThTRiH7kLifQXWUdi9j' // this is needed for custom overrides
    }
  }
};

const prodExternalModules: ContxtSdkExternalModulesType = {
  admin: {
    clientId: '8qY2xJob1JAxhmVhIDLCNnGriTM9bct8',
    host: 'https://contxt.api.ndustrial.io',
    module: AdminService
  },
  coincident_peak: {
    clientId: 'ctO2dyQmBKx181xGQ3zGc7u4Ox07PaR3',
    host: 'https://peaks.api.ndustrial.io',
    module: EmptyService
  },
  ems: {
    clientId: 'e2IT0Zm9RgGlDBkLa2ruEcN9Iop6dJAS',
    host: 'https://ems.api.ndustrial.io',
    module: EmptyService
  },
  iot_v2: {
    clientId: 'ZPrYMWVCcsyYaKKK2uiFLS71X1MB7zJP',
    host: 'https://iot.api.ndustrial.io/v2/sources',
    module: EmptyService
  },
  rates: {
    clientId: 'Z74s6YwxjVPGkMCZmRBhedIgMrckM0Sd',
    host: 'https://rates-v2.api.ndustrial.io',
    module: EmptyService
  }
};

const stagingSdkConfig: SdkConfig = {
  domain: 'ndustrial.auth0.com',
  customModuleConfigs: {
    contxtAuth: {
      env: 'production',
      clientId: '7TceUsM1eC4nKmdoC717383DWyfc9QoY' // this is needed for custom overrides
    },
    coordinator: {
      env: 'production',
      clientId: 'qGzdTXcmB57zlTp86rYsivG9qEss1lbF' // this is needed for custom overrides
    },
    events: {
      env: 'staging',
      clientId: 'dn4MaocJFdKtsBy9sFFaTeuJWL1nt5xu' // this is needed for custom overrides
    },
    facilities: {
      env: 'staging',
      clientId: 'xG775XHIOZVUn84seNeHXi0Qe55YuR5w' // this is needed for custom overrides
    },
    files: {
      env: 'staging',
      clientId: 'SLE310LY4nDT5p06sUGPVOLlGXk5osbQ' // this is needed for custom overrides
    },
    iot: {
      env: 'production',
      clientId: 'm35AEcxD8hf65sq04ZU7yFxqpqVkKzES' // this is needed for custom overrides
    },
    nionic: {
      env: 'staging',
      clientId: 'vhGxildn8hRRWZj49y18BGtbjTkFHcTG' // this is needed for custom overrides
    }
  }
};

const stagingExternalModules: ContxtSdkExternalModulesType = {
  // same in staging/prod
  admin: prodExternalModules.admin,
  // same in staging/prod
  coincident_peak: prodExternalModules.coincident_peak,
  ems: {
    clientId: 'vMV67yaRFgjBB1JFbT3vXBOlohFdG1I4',
    host: 'https://ems.api.staging.ndustrial.io',
    module: EmptyService
  },
  // same in staging/prod
  iot_v2: prodExternalModules.iot_v2,
  rates: {
    clientId: 'A3t0zn44aURi2Qnldh95GtD5HvP7P5i9',
    host: 'https://rates-v2.api.staging.ndustrial.io',
    module: EmptyService
  }
};

export { EmptyService };
